import './App.css';
import React, { useState, useEffect } from 'react';
import confetti from 'https://cdn.skypack.dev/pin/canvas-confetti@v1.9.3-ZVXR27AiU3rMhLWbmiMx/mode=imports/optimized/canvas-confetti.js';

function App() {
  const [searchTerm, setSearchTerm] = useState('');

  /* Puzzle Data from backend */
  const [puzzleData, setData] = useState(); // State for fetched data

  const [isLoading, setIsLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for errors
  const [won, setWon] = useState(false); // State for if the user has won
  const [lost, setLost] = useState(false); // State for if the user has lost

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://shsteachle-backend-1996f111b254.herokuapp.com');
        if (!response.ok) throw new Error('Failed to fetch data');
        const result = await response.json();
        setData(result);
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        setError(err.message);
      }
    };
    fetchData();
  }, []);

  if (error != null) {
    return (
      <div style={{
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 2,
      }}>
        <div className="z-1 fixed shadow-lg card bg-neutral text-neutral-content w-96" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          color: 'white',
          transform: 'translate(-50%,-50%)',
          msTransform: 'translate(-50%,-50%)',
        }}>
        <div className="card-body items-center text-center">
          <h2 className="card-title"><u>Error!</u></h2>
          <p>If this continues, please reach out to Alex.</p>
        </div>
      </div>
      </div>
    )
  }
  if (isLoading === true) {
    return (
      <div style={{
        position: 'fixed',
        display: 'flex',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 2,
      }}>
        <div className="z-1 fixed shadow-lg card bg-neutral text-neutral-content w-96" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          color: 'white',
          transform: 'translate(-50%,-50%)',
          msTransform: 'translate(-50%,-50%)',
        }}>
        <div className="card-body items-center text-center">
          <h2 className="card-title"><u>Loading...</u></h2>
          <p>Fetching todays puzzle...</p>
        </div>
      </div>
      </div>
    )
  }

  const filteredTeachers = puzzleData.items.filter((item) => {
    if (searchTerm === '') return puzzleData.items;
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  let placeholder = "https://i.imgur.com/xItoKcW.jpeg";
  let lives = 3;
  let submitting = false;

  // Cookies are formatted as "{round};{lives}" (lives are not used yet)
  function setRoundCookie(round) {
    document.cookie = (round === -1) ? "" : round;
  }
  function getRoundCookie() {
    return (document.cookie.length > 0) ? document.cookie : null;
  }
  function alreadySolved() {
    //console.log(puzzleData.round, getCookie());
    if (getRoundCookie() === null || puzzleData.round > getRoundCookie()) return false;
    return true;
  }

  /* Time Until Midnight (Found on stack overflow!) */
  function timeUntilMidnight() {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 0, 0, 0);
  
    const timeDifference = midnight - now;
  
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  
    return { hours, minutes, seconds };
  }
  
  /* Search Bar */
  function handleSearch(e) {
    setSearchTerm(e.target.value);
  }

  /* Drag and Drop */
  function onDragStart(e, item) {
    console.log('dragging');
    e.dataTransfer.setData('application/json', JSON.stringify(item));
  }
  function handleDrop(e) {
    if (error != null || isLoading || won) return;
    if (won) return;
    console.log('dropped');
    e.preventDefault();
    e.target.classList.remove('border-4', 'border-dashed');

    if (!e.dataTransfer.getData('application/json')) return;
    const data = JSON.parse(e.dataTransfer.getData('application/json'));

    e.target.setAttribute('data-teacher-name', data.name);
    e.target.src = data.img;

    e.target.classList.remove('border-green-500');
    e.target.classList.remove('border-red-500');
  }
  function handleDragOver(e) {
    e.preventDefault();
    e.target.classList.add('border-4', 'border-dashed', 'border-gray-500');
  }
  function handleDragLeave(e) {
    e.preventDefault();
    e.target.classList.remove('border-4', 'border-dashed');
  }

  /* Game Related Functions */
  function getLifeEmojis() {
    let emojis = "";
    for (let i = 0; i < lives; i++) {
      emojis += "❤️";
    }
    for (let i = 0; i < 3 - lives; i++) {
      emojis += "🩶";
    }
    return emojis;
  }
  function getAnswers(row, col) {
    return puzzleData.puzzle.answers[`${row}-${col}`];
  }
  function getTeacherInSlot(row, col) {
    return document.getElementById(`spot-${row}-${col}`).getAttribute('data-teacher-name'); // next step is put these in the elements
  }
  /*function getAllTeachers() {
    let teachers = [];
    for (let i = 1; i <= 3; i++) {
      for (let j = 1; j <= 3; j++) {
        teachers.push(getTeacherInSlot(i, j));
      }
    }
    return teachers
  } */

  function markBlank(row, col) {
    document.getElementById(`spot-${row}-${col}`).classList.remove('border-green-500');
    document.getElementById(`spot-${row}-${col}`).classList.remove('border-red-500');
  }
  function markCorrect(row, col) {
    document.getElementById(`spot-${row}-${col}`).classList.add('border-green-500');
  }
  function markIncorrect(row, col) {
    document.getElementById(`spot-${row}-${col}`).classList.add('border-red-500');
  }

  function viewAnswerButton() {
    console.log('viewing answers');
  }

  function submitButton() {
    if (submitting === true || error != null || won) return;
    if (submitting === true || won) return;
    submitting = true;

    let correct = true;
    let count = 0;

    for (let i = 1; i <= 3; i++) {
      for (let j = 1; j <= 3; j++) {
        markBlank(i, j);
        let possibleAnswers = getAnswers(i, j);
        if (!possibleAnswers.includes(getTeacherInSlot(i, j))) {
          setTimeout(() => {
            markIncorrect(i, j);
          }, (count * 400));
          correct = false;
        } else {
          setTimeout(() => {
            markCorrect(i, j);
          }, (count * 400));
        }
        
        count++;
      }
    }
    
    setTimeout(() => {
      if (correct === false) {
        lives--;
        document.getElementById('lives-display').innerText = getLifeEmojis();
        submitting = false;
  
        if (lives <= 0) {
          setRoundCookie(puzzleData.round);
          setLost(true);
        }
      } else {
        setRoundCookie(puzzleData.round);
        setWon(true);
        confetti();
      }
    }, ((count-1) * 400));
  }

  /* HTML */
  return (
    <div className="flex justify-center items-center h-screen bg-gray-900">
      {/* <!-- Sidebar for teacher list and search --> */}
      <div className="w-1/4 bg-gray-800 text-white p-4 shadow-lg flex flex-col rounded-md">
        {/* <!-- Search Bar --> */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search Teachers"
            value={searchTerm}
            onChange={handleSearch}
            className="input input-bordered w-full bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
          />
        </div>
        
        {/* <!-- Teacher List --> */}
        <div className="overflow-y-auto max-h-96 space-y-4">
          {isLoading === false && (filteredTeachers.map((item, index) => (
            <div 
            draggable onDragStart={(e) => onDragStart(e, item)}
            key={index} className="flex items-center gap-4 p-2 hover:bg-gray-700 rounded-md">
              <img
                src={item.img}
                alt={`${item.name}'s picture`}
                className="w-11 h-13 rounded-lg"
              />
              <div className="text-lg font-semibold">{item.name}</div>
            </div>
          )))}
          {(isLoading === true) && (
            <div className="text-center text-lg font-semibold">No teachers found...</div>
          )}
        </div>
      </div>
      
      {/* <!-- 3x3 Grid and Labels --> */}
      <div className="flex flex-col p-6 bg-gray-800 shadow-lg rounded-lg w-1/2 mx-4">
        {/* <!-- Column Labels --> */}
        <div className="grid grid-cols-4 gap-4 text-white">
          <div></div> {/*<!-- Empty space for row labels --> */}
          <div id="column-1-text" className="text-center text-lg font-semibold">{puzzleData.puzzle.column[0]}</div>
          <div id="column-2-text" className="text-center text-lg font-semibold">{puzzleData.puzzle.column[1]}</div>
          <div id="column-3-text" className="text-center text-lg font-semibold">{puzzleData.puzzle.column[2]}</div>
        </div>

        {/* <!-- Grid with Row Labels and Teacher Buttons --> */}
        <div className="grid grid-cols-4 gap-6 mt-6">
          {/* Row 1 */}
          <div id="row-1-text" className="flex justify-end items-center pr-4 text-right text-lg font-semibold text-white">{puzzleData.puzzle.row[0]}</div>
          <img 
            src={placeholder}
            alt="Teacher 1"
            id="spot-1-1"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
          <img 
            src={placeholder}
            alt="Teacher 2"
            id="spot-1-2"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
          <img 
            src={placeholder}
            alt="Teacher 3"
            id="spot-1-3"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>

          {/* Row 2 */}
          <div id="row-2-text" className="flex justify-end items-center pr-4 text-right text-lg font-semibold text-white">{puzzleData.puzzle.row[1]}</div>
          <img 
            src={placeholder}
            alt="Teacher 4"
            id="spot-2-1"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
          <img 
            src={placeholder}
            alt="Teacher 5"
            id="spot-2-2"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
          <img 
            src={placeholder}
            alt="Teacher 6"
            id="spot-2-3"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>

          {/* Row 3 */}
          <div id="row-3-text" className="flex justify-end items-center pr-4 text-right text-lg font-semibold text-white">{puzzleData.puzzle.row[2]}</div>
          <img 
            src={placeholder}
            alt="Teacher 7"
            id="spot-3-1"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
          <img 
            src={placeholder}
            alt="Teacher 8"
            id="spot-3-2"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
          <img 
            src={placeholder}
            alt="Teacher 9"
            id="spot-3-3"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            className="flex justify-center items-center w-30 h-40 border-8 border-gray-500 rounded-lg bg-gray-700">
          </img>
        </div>

        {/* ❤️❤️🩶 */}
        <h1 id="lives-display" className="text-center text-5xl">{getLifeEmojis()}</h1>
        <div className="flex flex-col items-center pt-3"><button 
          className="btn btn-success w-1/3 content-centert text-xl"
          onClick={submitButton}
        >Submit</button></div>
      </div>

      {/* <!-- Information + Changelog --> */}
      <div className="w-1/4 bg-gray-800 text-white p-4 shadow-lg flex flex-col rounded-md">
        <h1 className="text-2xl font-semibold">SHSTeachle</h1>
        <p className="mt-2">Drag and drop <u>Teachers</u> from the left onto the squares</p>
        <p>based on if they fit the row and columns topic.</p>
        <h1 className="text-2xl font-semibold mt-4">Changelog</h1>
        <h2 className="text-xl font-semibold mt-4">Temp Release (12/12/24)</h2>
        <p className="mt-2">- Opened the site temporarily</p>
      </div>

      {/* Already Solved Popup */}
      {(alreadySolved() === true && lost === false && won === false) && (
        <div style={{
          position: 'fixed',
          display: 'flex',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 2,
        }}>
          <div style={{
            position: 'fixed',
            display: 'flex',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 2,
          }}>
            <div className="z-1 fixed shadow-lg card bg-neutral text-neutral-content w-96" style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              color: 'white',
              transform: 'translate(-50%,-50%)',
              msTransform: 'translate(-50%,-50%)',
            }}>
              <div className="card-body items-center text-center">
                <h2 className="card-title"><u>You already did todays puzzle!</u></h2>
                <p>Come back tommorow for the next puzzle.</p>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Lost Popup */}
      {lost === true && (
        <div style={{
          position: 'fixed',
          display: 'flex',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 2,
        }}>
          <div className="z-1 fixed shadow-lg card bg-neutral text-neutral-content w-96" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            'font-size': '50px',
            color: 'white',
            transform: 'translate(-50%,-50%)',
            msTransform: 'translate(-50%,-50%)',
          }}>
          <div className="card-body items-center text-center">
            <h2 className="card-title"><u>You Lost!</u></h2>
            <div className="card-actions justify-end">
              <button className="btn btn-info" onClick={viewAnswerButton}>View Answer</button>
            </div>
          </div>
        </div>
        </div>
      )}

      {/* Won Popup */}
      {won === true && (
        <div style={{
          position: 'fixed',
          display: 'flex',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: 2,
        }}>
          <div className="z-1 fixed shadow-lg card bg-neutral text-neutral-content w-96" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            color: 'white',
            transform: 'translate(-50%,-50%)',
            msTransform: 'translate(-50%,-50%)',
          }}>
            <div className="card-body items-center text-center">
              <h2 className="card-title"><u>You Won!</u></h2>
              <p>Come back tommorow for the next puzzle!</p>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default App;
